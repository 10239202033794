$primary: #40e1ff;
$accent: #9b82ff;
$bg: #202020;
$bg-secondary: #2a2a2b;

.megacat {
  .cdk-overlay-container {
    position: fixed;
    z-index: 999999;
  }

  mat-dialog-container {
    background: whitesmoke;
  }

  button.mcm {
    span {
      margin-left: 10px;
      &.mat-button-focus-overlay {
        margin-left: 0px;
      }
    }
  }

  div,
  fuse-vertical-navigation {
    &.mcm-brand {
      color: $primary;
    }

    &.mcm-bg {
      background-color: $bg;
    }
  }

  p {
    color: #fff;
  }
  landing-home {
    display: block;
  }
  .site-wrapper {
    max-width: 1280px;
    margin: 0 auto;
    &.flex {
      display: flex;
      justify-content: space-between;
    }
  }
  @media (max-width: 991px) {
    .site-wrapper {
      max-width: 300px;
    }
    app-header {
      height: 60px !important;
    }
  }

  app-header {
    width: 100%;
    position: sticky;
    z-index: 9999;
    top: 0;
    display: block;
    height: 70px;
    header {
      background-color: $bg;
      padding: 0px;
      border-bottom: 1px solid $bg-secondary;
      .site-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      .site-identity {
        width: 230px;
      }

      nav {
        &.navbar {
          padding: 0 !important;
        }
        .navbar-collapse {
          justify-content: flex-end;

          ul {
            &.navbar-nav {
              margin: 0 !important;
              align-items: center;
            }
            li {
              list-style: none;
              padding: 15px;
              height: 70px;
              display: flex;
              align-items: center;
              justify-content: center;
              a {
                color: rgba(255, 255, 255, 0.75);
                font-size: 13px;
                font-weight: 700;
                padding: 10px 5px;
                text-decoration: none;
              }
              &:hover {
                background-color: $bg-secondary;
              }
            }
          }
          .actions {
            display: flex;
            height: 70px;
            align-items: center;
            button {
              border-radius: 100px;
              font-size: 16px;
              display: block;
              &.btn-primary {
                color: #fff;
                font-weight: 700;
                margin-left: 10px;
                padding: 9px 40px;
                &.mcl {
                  background-color: $primary;
                  &:hover {
                    background-color: $accent;
                  }
                }
              }
              &.mcl-notif {
                color: $primary;
                border-radius: 100px;
                width: 44px;
                height: 44px;
                display: block;
                &:hover {
                  background-color: $accent;
                }
              }
              &.cart-items {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #151515;
                padding: 0px 15px 0px 0px;
                color: #fff;
                font-size: 12px;
                height: 40px;
                .notification-icon {
                  background: transparent;
                  padding: 0 !important;
                  height: auto;
                  &:hover {
                    background: transparent !important;
                  }
                }
              }
            }
            .mcs-account-dropdown-wrapper-user {
              &:hover {
                .dropdown-wrapper {
                  overflow: auto;
                }
              }
            }
            .mcs-account-dropdown-wrapper {
              height: 70px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 10px 20px;
              position: relative;
              cursor: pointer;
              transition: ease-in-out 0.2s;
              &:hover {
                background-color: $bg-secondary;
              }
              a {
                &.user {
                  font-size: 13px;
                  color: #fff;
                  text-decoration: none;
                }
              }

              .wallet-connect {
                font-size: 10px !important;
                color: $primary;
                text-transform: uppercase;
              }
              .wallet-address {
                font-size: 12px !important;
                color: $primary;
                .label {
                  text-transform: uppercase;
                  color: #ffffff5e;
                  font-size: 8px;
                }
              }
              .dropdown-wrapper {
                position: absolute;
                width: 100%;
                top: 100%;
                background: $bg-secondary;
                left: 0;
                overflow: hidden;
                height: 0px;
                transition: ease-in-out 0.2s;
                box-shadow: 0px 15px 10px rgba(0, 0, 0, 0.5);
                ul {
                  list-style: none;
                  padding: 0px;
                  margin: 0px;

                  li {
                    padding: 15px !important;
                    height: auto !important;
                    align-items: center !important;
                    justify-content: start !important;
                    border-top: 1px solid rgba(#fff, 0.05);
                    span {
                      color: #fff;
                      &.icon {
                        width: 35px;
                        height: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 10px;
                        position: relative;
                        z-index: 2;
                        &::before {
                          display: block;
                          content: '';
                          width: 100%;
                          height: 100%;
                          position: absolute;
                          background: #5de7ff;
                          z-index: 1;
                          border-radius: 100%;
                          transform: scale(0);
                          transition: ease-in-out 0.2s;
                        }
                        svg {
                          position: relative;
                          z-index: 1;
                        }
                      }
                    }
                    &:hover {
                      background-color: rgba(#fff, 0.05);
                      span {
                        &.icon {
                          &::before {
                            transform: scale(1);
                          }
                        }
                      }
                    }
                  }
                }
              }
              &:hover {
                .dropdown-wrapper {
                  height: auto;
                }
              }

              @media (max-height: 850px) {
                &:hover {
                  .dropdown-wrapper {
                    height: 300px;
                    overflow: auto;
                  }
                }
              }
            }
          }
        }
      }
      .btn-primary.mcl {
        display: flex;
        padding: 10px 15px;
        text-decoration: none;
        border-radius: 100px;
        font-size: 13px;
        align-items: center;
        justify-content: center;
        height: 40px;
        color: #000;
        svg {
          margin-right: 5px;
          transform: scale(0.8);
        }
      }
    }
  }

  app-footer {
    width: 100%;
    footer {
      background-color: #3a3a3b;
      .site-wrapper {
        display: flex;
        .col {
          width: 15%;
          &:nth-child(1) {
            width: 55%;
            padding-right: 50px;
          }
        }
        p {
          font-size: 16px;
          margin-top: 20px;
        }
        nav {
          padding-left: 50px;
          span {
            &.title {
              font-size: 16px;
              color: #fff;
              font-weight: bold;
              display: block;
              margin-bottom: 20px;
              text-decoration: underline;
            }
          }
          li {
            list-style: none;
            margin-bottom: 10px;
            a {
              color: #fff;
              font-size: 16px;
            }
          }
        }
        &.secondary-footer {
          background-color: $bg;
          margin-top: 50px;
          padding: 20px;
          border-radius: 20px;
          justify-content: space-between;
          span {
            color: #fff;
          }
          nav {
            li {
              display: inline;
              padding: 10px;
              a {
                color: #fff;
                font-size: 14px;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
  //Section specific stylings
  section {
    // background-color: #44423bc4;
    padding: 35px 0px;
    &.offset {
      box-shadow: inset 0px 0px 100px rgba(0, 0, 0, 0.5);
    }
    &.statistics {
      app-card {
        display: flex;
        justify-content: space-between;
      }
      @media (max-width: 991px) {
        app-card {
          flex-direction: column;
        }
      }
    }
    h2 {
      &.title {
        font-size: 40px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 30px;
      }
    }
  }

  //MCL Card styles
  app-card {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.4);
    background-color: $bg-secondary;
    width: 100%;
    padding: 20px;
    display: block;
    border-radius: 20px;
    background-size: cover !important;
    background-position: center;
    app-product:hover .img {
      transform: scale(1.2);
    }
  }

  .custom-css-class {
    font-size: 16px;
    font-weight: 600;
    background-color: #5b5353;
    .mat-simple-snackbar-action {
      color: #ff4081 !important;
    }
  }

  .mcl-view-option {
    display: flex;
    height: auto;
    box-sizing: border-box;
    background: #151515;
    border-radius: 10px;
    position: relative;
    justify-content: space-evenly;
    .option {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      cursor: pointer;
      border-radius: 10px;
      &.selected {
        background: #40e1ff;
      }

      /*== common styles for both parts of tool tip ==*/
      &::before,
      &::after {
        opacity: 0;
        position: absolute;
        z-index: -100;
      }

      &:hover::before,
      &:focus::before,
      &:hover::after,
      &:focus::after {
        opacity: 1;
        transform: scale(1) translateY(0);
        z-index: 100;
      }

      /*== pointer tip ==*/
      &::before {
        border-style: solid;
        border-width: 1em 0.75em 0 0.75em;
        border-color: #3e474f transparent transparent transparent;
        bottom: 100%;
        content: '';
        margin-left: -0.5em;
        transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26), opacity 0.65s 0.5s;
        transform: scale(0.6) translateY(-90%);
      }

      &:hover::before,
      &:focus::before {
        transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
      }

      /*== speech bubble ==*/
      &::after {
        background: #3e474f;
        border-radius: 0.25em;
        bottom: 120%;
        color: #edeff0;
        content: attr(data-tip);
        // margin-left: -8.75em;
        padding: 1em;
        transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
        transform: scale(0.6) translateY(50%);
        width: 10em;
        text-align: center;
        pointer-events: none;
      }

      &:hover::after,
      &:focus::after {
        transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
      }

      @media (max-width: 760px) {
        &::after {
          font-size: 0.75em;
          margin-left: -5em;
          width: 10em;
        }
      }
    }
    &::before {
      content: '';
      display: none;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 5px;
      left: 5px;
      background: $primary;
      border-radius: 6px;
    }
  }

  .profile-header {
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.25);
    h2 {
      color: #fff;
    }
  }

  // FORM STYLING  DARK THEME

  .dark-theme {
    &.flex {
      display: grid;
      grid-template-columns: 65% 30%;
      grid-gap: 5%;
      padding-top: 50px;
    }
    .group-fields {
      width: 80%;
      margin: 0px auto 75px;
      .field-row {
        margin-bottom: 25px;
        p {
          text-align: left;
          font-size: 12px;
          margin-bottom: 20px;
          color: #ffffff73 !important;
        }
        .label {
          font-weight: bold !important;
          color: #e5e8eb !important;
          font-size: 18px !important;
          display: block;
          margin-bottom: 15px;
          span {
            display: block;
            font-size: 12px;
            font-weight: 300;
            color: #ffffff80;
          }
          i {
            color: red;
          }
        }
        &.metadata {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              .mat-form-field-label-wrapper {
                .mat-form-field-label {
                  font-size: 14px !important;
                  color: #fff !important;
                }
              }
            }
          }
          .two-col-fields {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            align-items: start;
            .btn {
              &.add {
                padding: 20px 15px;
                margin-top: 22px;
                margin-left: 10px;
                background: #1b1b1b;
                transition: ease-in-out 0.2s;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  background: $primary;
                }
              }
            }
          }
        }
      }
      .mat-form-field-wrapper {
        .mat-form-field-flex {
          cursor: text;
          display: flex;
          background-color: rgb(53, 56, 64) !important;
          border-radius: 10px;
          border: 1px solid rgb(21, 27, 34) !important;
          width: 100%;
          padding: 6px;
          box-shadow: none !important;
          .mat-input-element {
            padding: 10px !important;
            font-size: 22px;
            color: #fff;
          }
          textarea {
            &.mat-input-element {
              transform: none;
              height: 78%;
              margin: 0 !important;
            }
          }
          .mat-form-field-label-wrapper {
            top: -35px !important;
            color: rgb(229, 232, 235);
            .mat-form-field-label {
              font-weight: bold !important;
              color: rgb(229, 232, 235) !important;
              font-size: 18px !important;
              span {
                color: red;
              }
            }
          }
        }
        .mat-error {
          color: red;
          margin-top: 10px !important;
        }
      }
      .mat-focused {
        .mat-form-field-wrapper {
          border: none !important;
          .mat-form-field-flex {
            cursor: text;
            display: flex;
            background-color: rgb(76, 80, 92) !important;
            border-radius: 10px;
            border: 1px solid rgb(21, 27, 34) !important;
            width: 100%;
            padding: 6px;
            box-shadow: none !important;
          }
        }
        &:not(.mat-form-field-invalid) {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              border: none !important;
            }
          }
        }
      }
      .wallet-address-wrapper {
        width: 100%;
        -moz-box-align: center;
        align-items: center;
        background-color: rgba(138, 147, 155, 0.06);
        border-radius: 10px;
        border: 1px solid rgb(21, 27, 34);
        display: flex;
        height: 58px;
        padding: 0px 12px;
        color: #fff;
        justify-content: space-between;
      }
      .field {
        margin-bottom: 25px;
        span {
          &.label {
            font-weight: bold !important;
            color: #e5e8eb !important;
            font-size: 18px !important;
            margin-bottom: 10px;
            display: block;
          }
        }
        .profile-photo {
          width: 150px;
          height: 150px;
          background-size: cover !important;
          border-radius: 100%;
          border: 5px solid #202020;
          position: relative;
          overflow: hidden;
          #image {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
          }
          .change-profile-hover {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(32, 32, 32, 0.66);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            opacity: 0;
            i {
              font-size: 11px;
              color: #fff;
            }
          }
          &:hover {
            .change-profile-hover {
              opacity: 1;
            }
          }
          &.banner {
            height: 150px !important;
            border-radius: 10px !important;
          }
        }
      }

      .btn {
        &.primary {
          background: #40e1ff;
          display: flex;
          padding: 15px 25px;
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          svg {
            margin-left: 10px;
            width: 0 !important;
            transition: ease-in-out 0.2s;
          }
          &:hover {
            svg {
              width: 20px !important;
            }
          }
        }
      }

      .file-upload-wrapper {
        width: 100%;
        padding: 20px;
        border: dashed 2px #e5e8eb;
        border-radius: 10px;
        text-align: center;
        position: relative;
        cursor: pointer;
        min-height: 150px;
        #image,
        #nftBanner {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
          z-index: 2;
          cursor: pointer;
        }
        svg {
          margin: 50px auto;
          transform: scale(2);
        }
        &:hover {
          background: #151515;
          .icon-wrapper {
            background: #202020d4;
            &.update {
              opacity: 1;
            }
          }
        }
        .product-image {
          max-height: 300px;
          width: auto;
          margin: 0 auto;
        }

        .icon-wrapper {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 1;
          &.update {
            opacity: 0;
          }
        }
      }
    }
  }

  .form-wrapper {
    width: 80%;
    margin: 0 auto;
    p {
      text-align: center;
    }
  }

  .loader-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    background: #000000a1;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
      color: #fff;
    }
  }
  .mcl-payment-modal {
    top: 60px;
    border-radius: 100px !important;
  }

  /*
  * Custom MCL Elements
  */

  .collection {
    &.grid {
      grid-template-columns: 23% 23% 23% 23%;
      grid-gap: 30px;
      margin: 50px 0px;
      &.small {
        grid-template-columns: 19% 19% 19% 19% 19%;
        grid-gap: 12px;
        .card {
          .action {
            .bid {
              .timer {
                font-size: 11px !important;
              }
              .label {
                font-size: 6px !important;
                span {
                  margin: 6px !important;
                }
              }
            }
            button {
              &.buy-now {
                padding: 15px 5px !important;
                font-size: 10px !important;
              }
            }
          }
        }
      }
    }

    @media (max-width: 850px) {
      &.grid {
        grid-template-columns: 50% 50%;
        margin-left: auto;
        margin-top: auto;
      }
    }
    @media (max-width: 450px) {
      &.grid {
        grid-template-columns: 80%;
        margin-left: auto;
        margin-top: auto;
        justify-content: left;
      }
    }
  }

  .mcl-select {
    width: 250px;
    background: #151515;
    color: #fff;
    font-size: 14px;
    border-radius: 10px;
    height: 50px;
    position: relative;
    z-index: 1;
    .selected {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      cursor: pointer;
      color: $primary;
    }
    .options {
      position: absolute;
      width: 100%;
      top: 83%;
      height: 0;
      overflow: hidden;
      background: #151515;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      .option {
        padding: 10px 15px;
        cursor: pointer;
        transition: 0s background-color;
        &:hover {
          background: $primary;
        }
      }
    }

    // &:hover {
    //     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    //     height: auto;
    //     .options {
    //         height: auto;
    //     }
    // }

    &.selecting-collapse {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      height: auto;
      .options {
        height: auto;
      }
    }
  }

  // temporary? fix for /home; sweet alert showing behind the carousel
  .swal2-container {
    z-index: 1000000 !important;
  }

  markdown {
    p {
      color: #40e1ff !important;
    }
    ul {
      list-style-type: disc;
      list-style-position: inside;
    }
    ol {
      list-style-type: decimal;
      list-style-position: inside;
    }
    ul ul,
    ol ul {
      list-style-type: circle;
      list-style-position: inside;
      margin-left: 15px;
    }
    ol ol,
    ul ol {
      list-style-type: lower-latin;
      list-style-position: inside;
      margin-left: 15px;
    }
  }
}

.collection-created-container,
.nft-created-container {
  top: 20% !important;
  left: 35% !important;
  width: 500px !important;
  position: absolute !important;
  height: 500px !important;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.54 !important;
  transform: translateY(0px) !important;
}

.date-block {
  display: flex;
}
.date-block__list {
  flex-basis: 150px;
  margin-right: 1%;
  .mat-form-field {
    width: 100%;
  }
}

.status-filter {
  max-width: 170px;
}

.type-filter {
  max-width: 170px;
  margin-left: 1%;
}

.filters-wrapper {
  margin-top: -1%;
  margin-left: 40%;
}

@media (max-width: 1050px) {
  .filters-wrapper {
    margin-left: 0%;
  }
}
